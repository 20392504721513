import { XXL_LAYOUT_WIDTH, LG_LAYOUT_WIDTH } from "pages/view/layout"

interface ImageSizesProps {
  /**
   * Ratio between image width and layout width
   */
  ratio: number
  /**
   * Ratio between image width and layout width on mobile
   */
  mobileRatio: number
}

/**
 * Generates image 'sizes' attribute value based on image width / layout width ratio.
 * More about image 'sizes` attribute: https://web.dev/learn/design/responsive-images/#sizes
 */
export const imageSizes = ({ ratio, mobileRatio }: ImageSizesProps) =>
  `(min-width: 1466px) ${XXL_LAYOUT_WIDTH * ratio}px, (min-width: 1024px) ${
    LG_LAYOUT_WIDTH * ratio
  }px, (min-width: 640px) ${ratio * 100}vw, ${mobileRatio * 100}vw`
