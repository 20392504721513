/** Effectful function (nullary) */
export type EffectFn0 = () => void

/** Effectful function (unary) */
export type EffectFn1<T> = (arg0: T) => void

/**
 * Types that specify which drag sources and drop targets are compatible.
 *
 * @see https://react-dnd.github.io/react-dnd/docs/overview#items-and-types
 */
export enum DragDropEnum {
  Block = "block",
  ContentSummary = "content-summary",
}
